<template>
  <div id="ResTest">
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-alert
          v-model="showAlertNoCorrect"
          variant="warning"
          dismissible
        >
          <h4 class="alert-heading">
            {{ $t("dataGeneric.atention") }}
          </h4>
          <div class="alert-body">
            <span>{{ $t("resources.minAnswersMsg") }}</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.create')"
        :back-button-text="$t('dataGeneric.goBack')"
        :next-button-text="$t('next')"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <!-- Información del recurso tab -->
        <tab-content
          :title="$t('resources.resourceData')"
          :before-change="validationForm"
        >
          <validation-observer
            ref="accountRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("resources.resourceInfoType") }} {{ $t("interactivities.quest") }}
                </h5>
                <small class="text-muted">
                  {{ $t("resources.wizardSubtitle") }}
                </small>
              </b-col>

              <b-col
                md="6"
                sm="12"
              >
                <b-form-group :label="$t('message.tableHeader.name') + ' *'">
                  <validation-provider
                    v-slot="validationContext"
                    name="name"
                    rules="required|min:3"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('resources.resourceName')"
                      maxlength="150"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                md="4"
                sm="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="question"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('resources.question') + ' *'"
                    :state="errors.length > 0 ? false : null"
                    :invalid-feedback="$t('required')"
                  >
                    <b-form-input
                      id="question"
                      v-model="question"
                      maxlength="150"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('resources.questionPlace')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group :label="$t('resources.introduction')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="description"
                  >
                    <b-form-textarea
                      v-model="comment"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('resources.questionnaireIntro')"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="4"
                sm="12"
              >
                <b-form-group :label="$t('resources.feedbackOK')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="feedbackOk"
                  >
                    <b-form-textarea
                      id="feedbackOk"
                      v-model="fedOk"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('resources.msgFeedbackOK')"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group :label="$t('resources.feedbackKO')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="feedbackError"
                  >
                    <b-form-textarea
                      id="feedbackError"
                      v-model="fedError"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('resources.msgFeedbackKO')"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <image-element-vue
                :image-origin="imageTestSelected"
                :image-type="'imageTestSelected'"
                :label="$t('resources.questionnaireImg')"
                @saveImage="loadImage"
              />
              <image-element-vue
                :image-origin="backgroundTestSelected"
                :image-type="'backgroundTestSelected'"
                :label-info="$t('infoImage.backgQuest')"
                :label="$t('resources.questionnaireBckg')"
                @saveImage="loadImage"
              />
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Configuracion del recurso tab -->
        <tab-content
          :title="$t('resources.answers')"
          :before-change="validationFormInfo"
        >
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("resources.answers") }}
                </h5>
                <small class="text-muted">{{
                  $t("resources.createOptions")
                }}</small>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-alert
                  v-model="showInfoMaxAnswers"
                  variant="success"
                  dismissible
                >
                  <h4 class="alert-heading">
                    Info
                  </h4>
                  <div class="alert-body">
                    <span>{{ $t("resources.maxAnswers") }}</span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-card>
                  <b-card-header class="d-flex justify-content-between">
                    <b-card-title>{{ $t("resources.answers") }}</b-card-title>
                    <span class="mr-2">{{ $t('answer') }}
                      <small>{{ $t('GoodWrong') }}</small></span>
                  </b-card-header>

                  <draggable
                    v-model="answers"
                    handle=".handle"
                  >
                    <b-card
                      v-for="(answer, index) in answers"
                      :key="'card_' + index"
                      :border-variant="answer.isTheCorrect ? 'success' : ''"
                      :bg-variant="answer.isTheCorrect ? 'success' : ''"
                      no-body
                      class="answer-box"
                    >
                      <b-row no-gutters>
                        <b-col
                          md="1"
                          lg="1"
                        >
                          <feather-icon
                            size="15"
                            icon="AlignJustifyIcon"
                            class="handle"
                          />
                        </b-col>
                        <b-col
                          class="answer-num"
                          md="1"
                          lg="1"
                        >
                          {{ index + 1 }}.
                        </b-col>
                        <b-col
                          md="8"
                          lg="8"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="'input_' + index"
                            rules="required"
                          >
                            <b-form-group :invalid-feedback="$t('required')">
                              <b-form-input
                                :id="'input_' + index"
                                v-model="answer.response"
                                maxlength="150"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('resources.createAnswer')"
                              />
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                          md="2"
                          lg="2"
                          class="d-flex justify-content-center"
                        >
                          <b-form-group :label-for="'checkbox_' + index">
                            <b-form-checkbox
                              :id="'checkbox_' + index"
                              v-model="answer.isTheCorrect"
                              name="anuncios"
                              :switch="true"
                              @change="checkOtherAnswers(index)"
                            />
                          </b-form-group>
                          <div>
                            <b-button
                              :disabled="answers.length > 2 ? false : true"
                              class="btn-icon rounded-circle"
                              variant="danger"
                              @click="dropSpecificQuestion(index)"
                            >
                              <feather-icon
                                icon="XCircleIcon"
                                size="17"
                              />
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </draggable>
                  <b-button
                    v-if="answers.length >= 2 && answers.length < 4"
                    @click="addNewAnswer"
                  >
                    {{ $t("resources.answerAdd") }}
                  </b-button>
                  <b-button
                    v-if="answers.length > 2"
                    class="btn btn-danger ml-2"
                    @click="dropLastAnswer"
                  >
                    {{ $t("resources.dropLastAnswer") }}
                  </b-button>
                </b-card>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import { showToast, utf8ToB64, messageError } from '@/store/functions'

import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import draggable from 'vuedraggable'
import {
  BAlert,
  BFormCheckbox,
  BFormTextarea,
  BCard,
  BRow,
  BCol,
  BCardHeader,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import ImageElementVue from '@/views/components/ImageElement.vue'

export default {
  components: {
    draggable,
    BAlert,
    BOverlay,
    ImageElementVue,
    BCard,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormTextarea,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BFormGroup,
    BFormInput,
  },
  props: {
    defaultLanguage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tipo: '',
      name: '',
      comment: '',
      inputs: null,
      required,
      question: null,
      fedError: null,
      fedOk: null,
      answers: [
        { response: null, isTheCorrect: false },
        { response: null, isTheCorrect: false },
      ],
      showAlertNoCorrect: false,
      showInfoMaxAnswers: false,
      imageTestSrc: null,
      imageTestSelected: null,
      imageTestSelectedPlaceholder: null,
      imageCategory: null,
      backgroundTestSrc: null,
      backgroundTestSelected: null,
      backgroundTestSelectedPlaceholder: null,
      backgroundCategory: null,
      show: false,
    }
  },
  methods: {
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'imageTestSelected':
          this.imageTestSelected = data

          break
        case 'backgroundTestSelected':
          this.backgroundTestSelected = data
          break
        default:
          break
      }
    },
    fix(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('required')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    checkOtherAnswers(indexTrue) {
      this.answers.forEach((element, index) => {
        if (index !== indexTrue) {
          // eslint-disable-next-line no-param-reassign
          element.isTheCorrect = false
        }
        if (index === indexTrue) {
          this.showAlertNoCorrect = false
        }
      })
    },
    addNewAnswer() {
      this.answers = [...this.answers, { response: null, isTheCorrect: false }]
      if (this.answers.length === 4) this.showInfoMaxAnswers = true
    },
    dropLastAnswer() {
      this.answers.pop()
      if (this.answers.length === 3) this.showInfoMaxAnswers = false
    },
    dropSpecificQuestion(index) {
      this.answers.splice(index, 1)
      if (this.answers.length === 3) this.showInfoMaxAnswers = false
    },
    async formSubmitted() {
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      this.show = true
      const userData = getUserData()
      const data = new FormData()
      const answerCorrect = this.answers.filter(
        item => item.isTheCorrect === true,
      )
      if (answerCorrect.length === 0) {
        this.showAlertNoCorrect = true
        document.scrollingElement.scrollTop = 0
        this.show = false

        return
      }
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
        mutation( $name: String!, $introduction: String, $fedKO: String, $fedOK: String, $client: ID!){
          createTest(input:{
            name: $name,
            intro: $introduction,
            failFeedback: $fedKO,
            successFeedback: $fedOK,
            client: $client
          }){
          test {
            id
            name
            intro
            client {
              id
              name
            }
          }
        }
        }
      `
      const variables = {
        name: this.question,
        introduction: this.comment,
        fedKO: this.fedError,
        fedOK: this.fedOk,
        client: userData.profile.client.id,
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      data.append('image', this.imageTestSelected)
      data.append('background', this.backgroundTestSelected)
      let testId
      await axios.post('', data, { headers }, config).then(res => {
        messageError(res, this)

        testId = res.data.data.createTest.test.id
        const queryAnswer = `
          mutation( $response: String!, $test: ID!, $order: Int, $client: ID!){
            createAnswer(input:{
              response: $response,
              test: $test,
              order: $order,
              client: $client
            }){
            answer {
              id
              response
              order
              test {
                id
                name
              }
              client {
                id
                name
              }
            }
          }
          }
        `
        this.answers.forEach((item, index) => {
          const answerVariable = {
            response: item.response,
            order: index + 1,
            client: userData.profile.client.id,
            test: testId,
          }
          const data2 = new FormData()
          data2.append('query', queryAnswer)
          data2.append('variables', JSON.stringify(answerVariable))
          axios.post('', data2, { headers }, config).then(result => {
            messageError(result, this)
          }).catch(() => {

          })
        })
      })
      const queryUpdate = `
        mutation( $testId: ID!, $correct: Int){
          updateTest(id: $testId, input:{
            correct: $correct,
          }){
          test {
            id
            name
            intro
            correct
            client {
              id
              name
            }
          }
        }
        }
      `
      const updateVariables = {
        testId,
        correct: this.answers.indexOf(answerCorrect[0]) + 1,
      }

      const formDataUpdate = new FormData()
      formDataUpdate.append('variables', JSON.stringify(updateVariables))
      formDataUpdate.append('query', queryUpdate)

      await axios.post('', formDataUpdate, { headers }, config).then(result => {
        messageError(result, this)
      }).catch(() => {

      })

      const queryResource = `
        mutation($name: String!, $client: ID!, $testId: ID){
          createResource(input:{
            name: $name,
            client: $client,
            type: TST,
            test: $testId,
            ${userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${userData.id}`)}",`}
          }){
          resource {
            id
            name
            type
            description
          }
        }
        }
      `

      const resourceVariables = {
        name: this.name,
        client: userData.profile.client.id,
        testId,
      }

      const formDataRes = new FormData()
      formDataRes.append('variables', JSON.stringify(resourceVariables))
      formDataRes.append('query', queryResource)

      axios.post('', formDataRes, { headers }, config).then(res => {
        messageError(res, this)

        this.show = false

        if (res.status === 200) {
          showToast(this.$t('success'), 1, this)
          this.$router.push('/contents/resources')
        }
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    chooseImage(imageClicked) {
      this.imageClicked = imageClicked
      document.getElementById(imageClicked).click()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#ResTest .answer-box {
  height: 70px;
  padding: 15px;
  opacity: 0.6;
}

#ResTest .answer-num {
  font-weight: 800;
  color: white;
  font-size: large;
}

#ResTest answers-card {
  background-color: #374565;
}
</style>
