<template>
  <section>
    <b-overlay
      variant="transparent"
      :show="overlay"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="mt-2 ml-3 mr-3 mb-3">
        <b-col md="6">
          <b-form-input
            v-model="searchInput"
            :placeholder="$t('slider.searchThreeCarac')"
            autofocus
            autocomplete="off"
          />
        </b-col>

        <b-col
          cols="4"
          class="d-flex justify-content-center"
        >
          <b-pagination
            v-if="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="lg"
            class=""
            @input="allProducts"
          />
        </b-col>
        <b-col cols="2">
          <b-button
            variant="primary"
            @click="confirmSelection"
          >
            {{ $t('slider.choosePr') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="dataArray.length == 0 && searchInput.length != 0"
          class="mb-2 ml-5"
        >
          <h5>{{ $t('subscriptions.notFound') }}</h5>
        </b-col>
        <b-col
          v-else-if="dataArray.length > 0 && searchInput.length != 0"
          class="mb-2 ml-5 text-left"
        >
          <h5>{{ $t('slider.selectProd') }}</h5>
        </b-col>
      </b-row>
      <b-row style="height:621px">
        <b-col
          style="height:603px"
          class="scroll-list list-group list-group-flush "
        >
          <b-table
            v-if="dataArray.length != 0"
            striped
            hover
            outlined
            :items="dataArray"
            responsive
            :fields="columns"
            :selectable="true"
            select-mode="single"
            class="text-left"
            @row-selected="onRowSelected"
          >
            <!-- image -->
            <template #cell(image)="data">
              <div class="">
                <b-img
                  :src="buildImageUrlBucket(data)"
                  class="tableImage"
                  @error="errorImg"
                />
              </div>
            </template>
            <!-- name -->
            <template #cell(name)="data">
              <div class="">
                {{ data.item.node.name }}
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" />
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BButton,
  BTable,
  BImg,
  BOverlay,
  BPagination,
  BCol,
  BRow,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError } from '@/store/functions'

import { getUserData } from '@/auth/utils'

const errorImage = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BCol,
    BRow,
    BTable,
    BImg,
    BOverlay,
    BFormInput,
    BPagination,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {

    columns: { type: Array, default: null },

  },
  data() {
    return {
      dataArray: [],
      searchInput: '',
      rows: null,
      rowSelected: null,
      overlay: false,
      perPage: 10,
      headers: {},
      userData: getUserData(),
      currentPage: 1,
    }
  },
  watch: {
    searchInput(oldValue, newValue) {
      if (newValue !== oldValue) {
        if (this.searchInput.length >= 3) {
          this.currentPage = 1
          this.allProducts()
        }
        if (this.searchInput.length < 3) {
          this.dataArray = []
        }
        if (this.searchInput.length === 0) {
          this.allProducts()
        }
      }
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.allProducts()
  },

  methods: {
    allProducts() {
      const { headers } = this
      this.overlay = true
      const userData = getUserData()
      axios
        .post('', {
          query: `
            query{  
              allProducts(
              first:${this.perPage},
              offset:${this.perPage * (this.currentPage - 1)},
              client:"${userData.profile.client.id}",
              name:"${this.searchInput}"      
              ){            
              totalCount
              edgeCount
              edges{
                node{
                  id
                  name
                  imageUrl
                  image

                }
              }
            }
          }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.dataArray = res.data.data.allProducts.edges
          this.rows = res.data.data.allProducts.totalCount
          this.overlay = false
        }).catch(() => {
        })
    },
    errorImg(e) {
      e.target.src = errorImage
    },
    buildImageUrlBucket(data) {
      if (data.item.node.imageUrl) {
        return data.item.node.imageUrl
      }
      return noCover
    },
    onRowSelected(item) {
      this.rowSelected = item.length ? item[0] : null
    },
    confirmSelection() {
      this.$emit('confirm-selection', this.rowSelected)
    },
  },
}
</script>

<style>
.tableImage {
  margin-right: 2rem;
  border-radius: 10px;
  width: 8rem;
}

.tableHeight {
  height: 700px;
}
</style>
