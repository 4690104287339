<template>
  <b-overlay
    variant="transparent"
    :show="show"
    :opacity="1.00"
    :blur="'2px'"
    rounded="lg"
  >
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('dataGeneric.create')"
      :back-button-text="$t('dataGeneric.goBack')"
      :next-button-text="$t('next')"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- Información del recurso tab -->
      <tab-content
        :title="$t('resources.resourceData')"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t('resources.resourceInfoType') }} DEEPLINK
              </h5>
              <small class="text-muted">
                {{ $t('resources.wizardSubtitle') }}
              </small>
            </b-col>

            <b-col
              md="6"
              sm="12"
            >
              <b-form-group :label="$t('message.tableHeader.name') + ' *'">
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="validationContext.errors.length > 0 ? false : null"
                    :placeholder="$t('resources.resourceName')"
                    maxlength="150"
                  />
                  <small class="text-danger">{{ validationMedia(validationContext) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group :label="$t('dataGeneric.description')">
                <b-form-textarea
                  id="description"
                  v-model="comment"
                  :placeholder="$t('resources.resourceDescription')"
                  rows="3"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="validationContext"
                name="android"
                rules="required|url"
              >
                <b-form-group label="DeepLink Android *">
                  <b-form-input
                    id="android"
                    v-model="android"
                    :state="validationContext.errors.length > 0 ? false : null"
                    placeholder="DeepLink Android"
                  />
                  <small class="text-danger">{{ validationMedia(validationContext) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                name="androidTV"
                rules="required"
              >
                <b-form-group
                  label="DeepLink Android TV *"
                  :state="errors.length > 0 ? false : null"
                  :invalid-feedback="$t('required')"
                >
                  <b-form-input
                    id="androidTV"
                    v-model="androidTV"
                    :state="errors.length > 0 ? false : null"
                    placeholder="DeepLink Android TV"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="validationContext"
                name="ios"
                rules="required|url"
              >
                <b-form-group label="DeepLink iOS *">
                  <b-form-input
                    id="ios"
                    v-model="ios"
                    :state="validationContext.errors.length > 0 ? false : null"
                    placeholder="DeepLink iOS"
                  />
                  <small class="text-danger">{{ validationMedia(validationContext) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                name="iosTV"
                rules="required"
              >
                <b-form-group
                  label="DeepLink tvOS *"
                  :state="errors.length > 0 ? false : null"
                  :invalid-feedback="$t('required')"
                >
                  <b-form-input
                    id="iosTV"
                    v-model="iosTV"
                    :state="errors.length > 0 ? false : null"
                    placeholder="DeepLink tvOS"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="validationContext"
                name="webApp"
                rules="required|url"
              >
                <b-form-group label="DeepLink Web APP *">
                  <b-form-input
                    id="webApp"
                    v-model="webApp"
                    :state="validationContext.errors.length > 0 ? false : null"
                    placeholder="DeepLink Web App"
                  />
                  <small class="text-danger">{{ validationMedia(validationContext) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </b-overlay>
</template>

<script>

import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getUserData } from '@/auth/utils'
import { utf8ToB64, messageError, showToast } from '@/store/functions'
import {
  BFormTextarea,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
  },
  props: {
    defaultLanguage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      name: '',
      comment: '',
      inputs: null,
      required,
      android: null,
      androidTV: null,
      ios: null,
      iosTV: null,
      webApp: null,
      show: false,
    }
  },
  methods: {
    validationMedia(data) {
      const keyContent = Object.keys(data.failedRules)
      if (keyContent.includes('required')) {
        return this.$t('required')
      }
      if (keyContent.includes('url')) {
        return this.$t('validUrl')
      }
      if (keyContent.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    formSubmitted() {
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      this.show = true
      const userData = getUserData()
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const queryResource = `
        mutation($name: String!, $client: ID!, $description: String, $android: String, $androidTv: String, $ios: String, $iosTv: String, $webApp: String ){
          createResource( input:{
            name: $name,
            description: $description
            client: $client,
            type: DEE,
            urlApp: $android,
            urlAppAndroidTv: $androidTv,
            urlAppIos: $ios,
            urlAppIosTv: $iosTv,
            ${userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${userData.id}`)}",`}
            urlAppWeb: $webApp           
          }){
          resource {
            id
            name
            type
            description
            urlApp
            urlAppAndroidTv
            urlAppIos
            urlAppIosTv
            urlAppWeb
          }
        }
        }
      `
      const resourceVariables = {
        name: this.name,
        description: this.comment,
        client: userData.profile.client.id,
        android: this.android,
        androidTv: this.androidTV,
        ios: this.ios,
        iosTv: this.iosTV,
        webApp: this.webApp,
      }
      data.append('variables', JSON.stringify(resourceVariables))
      data.append('query', queryResource)

      axios.post('', data, { headers }, config).then(res => {
        messageError(res, this)

        this.show = false
        if (res.status === 200) {
          showToast(this.$t('success'), 1, this)
          this.$router.push('/contents/resources')
        }
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style  lang="scss" >
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
