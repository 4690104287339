var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"id":"resInfo","variant":"transparent","show":_vm.show,"opacity":1.00,"blur":'2px',"rounded":"lg"}},[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":_vm.$t('dataGeneric.create'),"back-button-text":_vm.$t('dataGeneric.goBack'),"next-button-text":_vm.$t('next')},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":_vm.$t('resources.resourceData'),"before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("resources.resourceInfoType"))+" MÁS INFO ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("resources.wizardSubtitle"))+" ")])]),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|min:3","label-for":"name"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('message.tableHeader.name') + ' *'}},[_c('b-form-input',{attrs:{"id":"name","state":validationContext.errors.length > 0 ? false : null,"placeholder":_vm.$t('resources.resourceName'),"maxlength":"150"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.fix(validationContext)))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"escenarioName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('resources.scenarioName') + ' *',"state":errors.length > 0 ? false : null,"invalid-feedback":_vm.$t('required')}},[_c('b-form-input',{attrs:{"id":"escenarioName","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('resources.scenarioName')},model:{value:(_vm.escenarioName),callback:function ($$v) {_vm.escenarioName=$$v},expression:"escenarioName"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('dataGeneric.description')}},[_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('resources.resourceDescription'),"rows":"3"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"escenarioTitulo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('resources.scenarioTitle') + ' *',"invalid-feedback":_vm.$t('required')}},[_c('b-form-input',{attrs:{"id":"escenarioTitulo","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('resources.scenarioTitle')},model:{value:(_vm.escenarioTitulo),callback:function ($$v) {_vm.escenarioTitulo=$$v},expression:"escenarioTitulo"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('resources.scenarioText')}},[_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('resources.scenarioText'),"rows":"3"},model:{value:(_vm.textoMasInfo),callback:function ($$v) {_vm.textoMasInfo=$$v},expression:"textoMasInfo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('image-element-vue',{attrs:{"image-origin":_vm.imageSelected,"image-type":'imageSelected',"label-info":_vm.$t('infoImage.mInfo'),"label":_vm.$t('Image')},on:{"saveImage":_vm.loadImage}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }